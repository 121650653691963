
import { defineComponent, reactive, ref } from 'vue'
import md5 from 'js-md5'
import UserApi from '@/axios/api/user'
import { message } from 'ant-design-vue'
import store from '@/store/index'
import { UserOutlined } from '@ant-design/icons-vue'
import { useRouter, useRoute } from 'vue-router'
export default defineComponent({
  name: 'Login',
  components: {
    UserOutlined,
  },
  setup() {
    // 组件对象
    const ruleForm = ref()
    const router = useRouter()
    const route = useRoute()
    // const route = useRoute()
    const loading = ref(false)
    const loginFrom = reactive({
      loginName: '',
      loginPassword: '',
    })
    const rules = {
      loginName: [{ required: true, message: '请输入工号', trigger: 'blur' }],
      loginPassword: [{ required: true, message: '请输入密码', trigger: 'blur' }],
    }
    // 用户登录方法
    const onLogin = () => {
      console.log(123)
      ruleForm.value
        .validate()
        .then(() => {
          // 表单基本校验判断
          // MD5加密
          // loginFrom.loginPassword = md5(loginFrom.loginPassword)
          const reqLogin = {
            loginPassword: md5(loginFrom.loginPassword),
            loginName: loginFrom.loginName,
          }
          loading.value = true
          // 发送登录请求
          UserApi.login(reqLogin)
            .then((res) => {
              store.commit('setAuth', res.token)
              if (route.query.previouRouter) {
                router.replace(route.query.previouRouter as string)
              } else {
                router.replace('/home')
              }
              loading.value = false
            })
            .catch((err) => {
              loading.value = false
              message.error(err)
            })
        })
        .catch(() => {
          console.log(loginFrom)

          // 弹窗提示
          message.warning('工号和密码不能为空,信息填写有误。')
          return
        })
    }
    return {
      ruleForm,
      loginFrom,
      rules,
      onLogin,
      loading,
    }
  },
})
